import { useDimensions } from '@react-native-community/hooks';
import moment, { isDate } from 'moment';
import {
  ArrowForwardIcon,
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  HStack,
  Image,
  Input,
  Pressable,
  Text,
  VStack
} from 'native-base';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

/** COMPONENTS */
// import { PrivacyPolicyModal, TermsAndConditionsModal } from './Components';

/** CONFIGS */
import packageJSON from '../package.json';

/** FUNCTIONS */
import { convertHexToDecimal, generateMD5Hash, getStage2IdFromAPI } from './utils';

/** SVGs */
import CopySVG from './svg/copy.svg';

/** CONSTANTS */
const BORDER_RADIUS = 8;
const CAN_USE_CLIPBOARD = navigator?.clipboard?.writeText;
const DEFAULT_REDIRECT_URI = `https://streamelms.com/registration`;
const SPACING = 2;
const VALUE_TYPE_FORMATTING = {
  SSN: {
    formatDate: (v) => v,
    formatValue: (v) => v,
    label: 'Last 4 of SSN',
    placeholder: 'Last 4 of SSN'
  },
  ESPN: {
    /* Switch from 20th to 11th century */
    // formatDate: (v) => (v ? `10${v.substring(2, v.length)}` : v),
    formatDate: (v) => (v ? moment(v).subtract(900, 'years').format('YYYY-MM-DD') : v),
    formatValue: (v) => `${v}0`,
    label: 'ESPN Member ID',
    placeholder: 'ESPN member ID'
  }
};
const VALUE_TYPES = Object.keys(VALUE_TYPE_FORMATTING);

const App = () => {
  const windowWidth = useDimensions()?.window?.width;

  /* Input value (SSN/ESPN) */
  const [value, setValue] = useState(null);
  const [valueType, setValueType] = useState(VALUE_TYPES[0]);
  const formattedValue = VALUE_TYPE_FORMATTING[valueType].formatValue(value);

  /* Input date */
  const [date, setDate] = useState('');
  const formattedDate = VALUE_TYPE_FORMATTING[valueType].formatDate(date);
  const isDateValid = date.length >= 8 && moment(formattedDate).isValid();
  const showDateError = date.length > 8 && !isDateValid;

  /* Output hash */
  const [hash, setHash] = useState('');

  const isFormValidated = isDateValid && formattedValue && formattedValue?.length === 4 && !isNaN(formattedValue);

  // const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] = useState(false);
  // const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useState(false);

  const handleDateChange = (event) => setDate(event?.target?.value);
  const handleValueChange = (event) => setValue(event?.target?.value);

  const generateHash = async () => {
    try {
      /* HANDLED BY moment.js - Ensure MM and DD in birth date are 2 digits long (pad with leading 0 if needed) */
      /* HANDLED BY moment.js - Replace any "-"" in birth date with "/" characters */
      /* Put {formattedDate} from any date-like format (e.g., YYYY-MM-DD) into MM/DD/YYYY  */
      const formattedDate = VALUE_TYPE_FORMATTING[valueType].formatDate(date);
      let result = moment(formattedDate).format('MM/DD/YYYY');

      /* HANDLED BY {isFormValidated} - Ensure SSN4 is 4 digits (pad with leading 0 if needed) */
      /* Concatenate YYYY-MM-DDNNNN, and compute an MD5 hash in hex. */
      result = `${result}${value}`;
      result = generateMD5Hash(result);

      /* Take the first 8 digits of that hex, convert to decimal. */
      result = result.substring(0, 8);
      result = convertHexToDecimal(result);

      /* Take the first 10 digits of that result, pad with leading 0 if needed to ensure there are exactly 10 characters. */
      result = result.toString().substring(0, 10).padStart(10, '0');

      /* If the first character is 0, convert it to a 1. */
      if (result[0] === '0') result = `1${result.substring(1)}`;

      /* This results in the {stage1Id}, which is a 10 digit number starting with 1. */
      const stage1Id = result;
      const stage2Id = await getStage2IdFromAPI(stage1Id);

      setHash(stage2Id);
      tryCopyIDToClipboard(stage2Id);
    } catch (e) {
      console.log(e);
      // toast.error('An internal server error occurred. Please try again later.', {
      toast.error(e?.message ?? e ?? 'An internal server error occurred. Please try again later.', {
        autoClose: 5000,
        pauseOnHover: true,
        position: 'top-center',
        theme: 'light'
      });
    }
  };

  const tryCopyIDToClipboard = (hashParam) => {
    try {
      navigator.clipboard.writeText(hashParam ?? hash);
      toast.success('ID copied to clipboard.', {
        autoClose: 2000,
        pauseOnHover: true,
        position: 'top-center',
        theme: 'light'
      });
    } catch (e) {
      /* Do nothing */
    }
  };

  const navigateToRegistration = () => {
    const redirectURI = new URLSearchParams(window.location.search).get('redirectURI');
    const redirectURIWithStreamId = new URL(redirectURI ?? DEFAULT_REDIRECT_URI);
    redirectURIWithStreamId.searchParams.append('streamid', hash);

    window.open(redirectURIWithStreamId, '_blank');
  };

  const handlePrivacyPolicyPress = () => {
    // setIsPrivacyPolicyModalOpen(true);

    try {
      // eslint-disable-next-line no-restricted-globals
      location.href = 'https://www.nationwidechildrens.org/privacy-policy';
    } catch (e) {
      console.log(e);
    }
  };

  let containerWidth = undefined;
  if (windowWidth > 512 + SPACING * 4 * 2) containerWidth = 512;

  return (
    <Box alignItems="center" bg="primary.500" minH="100vh" p={SPACING * 2}>
      {/** Header and info */}
      <Box bg="primary.700" borderColor="primary.500" borderRadius={SPACING * 4} p={SPACING * 2} w={containerWidth}>
        <Text bold fontSize="3xl" textAlign="center">
          STREAM ID Generator
        </Text>
        <Divider borderColor="primary.500" my={SPACING * 2} />

        <Text fontSize="md">
          This site allows you to generate a STREAM ID in order to register on the STREAM eLMS site.
        </Text>
        <Box h={SPACING * 2} />

        <Text fontSize="md">
          Your date of birth and last 4 of your SSN are used to generate an encrypted and confidential identifier in
          order to keep your identity safe. This ID cannot be reversed to reveal any personal information.
        </Text>
        <Box h={SPACING * 2} />

        <Text fontSize="md" color="warning.300">
          ESPN members can use the toggle below to provide your 3-digit member ID instead of an SSN.
        </Text>
        <Box h={SPACING * 2} />

        <Text bold fontSize="md" italic>
          The personal information entered below is processed locally and does not leave your device.
        </Text>
        <Box h={SPACING * 2} />
      </Box>
      <Box h={SPACING * 2} />

      {/** Inputs and buttons */}
      <VStack bg="primary.700" borderColor="primary.500" borderRadius={SPACING * 4} p={SPACING * 2} w={containerWidth}>
        {/* Date of Birth input */}
        <FormControl isInvalid={showDateError} width="100%">
          <FormControl.Label>Date of Birth</FormControl.Label>
          <Input
            _focus={{ bg: 'white', borderColor: isDateValid ? 'success.300' : undefined }}
            bg="white"
            borderColor={isDateValid ? 'success.300' : undefined}
            color="black"
            fontSize="sm"
            placeholder="Date of Birth"
            py="3"
            size="xl"
            type="date"
            onChange={handleDateChange}
          />

          <FormControl.ErrorMessage>Date of birth is formatted incorrectly</FormControl.ErrorMessage>
          {!isDateValid && !showDateError && <FormControl.HelperText>Use MM/DD/YYYY format</FormControl.HelperText>}
          {isDateValid && (
            <Text color="success.600" marginTop={1}>
              Looks good!
            </Text>
          )}
        </FormControl>
        <Box h={SPACING * 2} />

        {/* SSN input */}
        <HStack alignItems="flex-end">
          <FormControl flex={1}>
            <FormControl.Label>{VALUE_TYPE_FORMATTING[valueType].label}</FormControl.Label>
            <Input
              _focus={{ bg: 'white' }}
              bg="white"
              color="black"
              fontSize="sm"
              placeholder={VALUE_TYPE_FORMATTING[valueType].placeholder}
              py="3"
              size="xl"
              type="password"
              onChange={handleValueChange}
            />
          </FormControl>
          <Box w={SPACING} />

          {/* Change {valueType} toggle */}
          <Button.Group isAttached variant="outline">
            {VALUE_TYPES.map((VALUE_TYPE) => (
              <Button
                key={VALUE_TYPE}
                colorScheme={valueType === VALUE_TYPE ? 'info' : 'muted'}
                onPress={() => setValueType(VALUE_TYPE)}
                variant={valueType === VALUE_TYPE ? 'solid' : 'outline'}
              >
                {VALUE_TYPE}
              </Button>
            ))}
          </Button.Group>
        </HStack>
        <Box h={SPACING * 2} />

        <HStack>
          {/* Generate button */}
          <Button
            alignSelf="flex-end"
            borderRadius={BORDER_RADIUS / 2}
            colorScheme={isFormValidated ? 'info' : 'muted'}
            isDisabled={!isFormValidated}
            flex={1}
            onPress={generateHash}
          >
            Generate
          </Button>
          <Box w={SPACING} />

          {/* Output */}
          <Input
            bg="white"
            bgColor="white"
            color="black"
            borderRadius={BORDER_RADIUS}
            editable={false}
            flex={2}
            InputRightElement={
              CAN_USE_CLIPBOARD && (
                <Pressable pr={2} onPress={() => tryCopyIDToClipboard(hash)}>
                  <Image src={CopySVG} height={4} width={4} tintColor="black" alt="Copy ID to clipboard" />
                </Pressable>
              )
            }
            value={hash ?? ''}
          />
        </HStack>

        <Collapse isOpen={hash}>
          <Text color="success.400" italic mt={SPACING}>
            Please store your STREAM ID somewhere safe for future reference. You can regenerate your ID here if you lose
            it.
          </Text>
        </Collapse>

        <HStack>
          <Button
            alignSelf="flex-end"
            borderRadius={BORDER_RADIUS / 2}
            colorScheme={hash ? 'success' : 'muted'}
            disabled={!hash}
            mt={SPACING * 2}
            w="100%"
            rightIcon={<ArrowForwardIcon />}
            onPress={navigateToRegistration}
          >
            Register
          </Button>
        </HStack>
      </VStack>

      {/** Footer */}
      <Box flex={1} justifyContent="flex-end" mt={8}>
        <HStack alignItems="center" flexWrap="wrap">
          <Pressable onPress={handlePrivacyPolicyPress}>
            <Text underline>Privacy Policy</Text>
          </Pressable>
          <Box w={SPACING * 4} />

          <Text>v{packageJSON.version}</Text>
          <Box w={SPACING * 4} />

          <Text>© 2023 Nationwide Children's Hospital</Text>
        </HStack>
      </Box>

      {/** Modals and toast */}
      {/* <PrivacyPolicyModal isOpen={isPrivacyPolicyModalOpen} onClose={() => setIsPrivacyPolicyModalOpen(false)} />
      <TermsAndConditionsModal isOpen={isTermsAndConditionsModalOpen} onClose={() => setIsTermsAndConditionsModalOpen(false)} /> */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Box>
  );
};

export default App;

/** PACKAGES */
import { extendTheme, NativeBaseProvider } from 'native-base';
import React from 'react';
import ReactDOM from 'react-dom/client';

/** VIEWS */
import App from './App';

/** UTILS */
import { reportWebVitals } from './utils/';

/** STYLE */
import 'react-toastify/dist/ReactToastify.css';

const UNDEFINED_COLOR_SHADES = {
  50: undefined,
  100: undefined,
  200: undefined,
  300: undefined,
  400: undefined,
  500: undefined,
  600: undefined,
  700: undefined,
  800: undefined,
  900: undefined
};

/** CONSTANTS */
const THEME = extendTheme({
  colors: {
    primary: {
      ...UNDEFINED_COLOR_SHADES,
      // 50: '#CED3DA',
      // 100: '#9DA8B5',
      // 200: '#6B7C90',
      300: '#3a506b',
      // 400: '#3A506B',
      500: '#1c2541',
      700: '#0b132b'
    },
    secondary: {
      ...UNDEFINED_COLOR_SHADES,
      300: '#6FFFE9',
      500: '#5bc0be'
    }
  },
  config: { initialColorMode: 'dark' }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NativeBaseProvider theme={THEME}>
      <App />
    </NativeBaseProvider>
  </React.StrictMode>
);

reportWebVitals();

// const API_INVOKE_URL = 'https://z4wfa2opue.execute-api.us-east-2.amazonaws.com/test/id/generate/';
const API_ENDPOINT = 'https://api.streamid.healthcare/id/generate/';

const getStage2IdFromAPI = async (stage1Id) => {
  const stage2IdResponse = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id: stage1Id })
  });

  const stage2IdJSON = await stage2IdResponse.json();
  const stage2Id = stage2IdJSON?.body?.id;

  if (stage2IdJSON.error) throw new Error(stage2IdJSON?.error);
  if (!stage2Id) throw new Error('An error occurred while generating the ID');
  return stage2Id;
};

export default getStage2IdFromAPI;
